import React, { Component } from "react";
import dbData from "../../config/db.json";
import axios from "axios";

class Subscribe extends Component {
  state = {
    initData: {},
    email: "",
    subscribeStatus: "",
  };

  constructor(props) {
    super(props);

    this.handleChangeEmail = this.handleChangeEmail.bind(this);
    this.subscribeNewsletter = this.subscribeNewsletter.bind(this);
  }

  handleChangeEmail(event) {
    let email = event.target.value;
    this.setState({ email });
    // console.log("email: " + this.state.email);
  }

  componentDidMount() {
    this.setState({
      initData: dbData.SubscribeSection.data,
    });
  }

  async subscribeNewsletter(event) {
    event.preventDefault();

    const headers = {
      headers: {
        "Content-Type": "application/json",
        "x-api-key": dbData.api.key,
      },
    };

    const data = {
      email: this.state.email,
    };

    const res = await axios.post(dbData.api.link + "/subscribe", data, headers);

    if (res.status === 200) {
      this.setState({
        email: "",
        subscribeStatus: this.state.initData.waitForConfirmation,
      });
    }

    console.log(res);
  }

  render() {
    return (
      <>
        <section className="section subscribe-area ptb_100">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 col-md-10 col-lg-7">
                <div className="subscribe-content text-center">
                  <h2>{this.state.initData.heading}</h2>
                  <p className="my-4">{this.state.initData.text}</p>
                  {this.state.subscribeStatus === "" ? (
                    ""
                  ) : (
                    <p className="my-4">{this.state.subscribeStatus}</p>
                  )}
                  <form
                    className="subscribe-form"
                    onSubmit={this.subscribeNewsletter}
                  >
                    <div className="form-group">
                      <input
                        type="email"
                        className="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        placeholder={this.state.initData.placeholder}
                        value={this.state.email}
                        onChange={this.handleChangeEmail}
                      />
                    </div>
                    <button type="submit" className="btn btn-lg btn-block">
                      {this.state.initData.submitText}
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default Subscribe;
