import React, { Component } from "react";
import dbData from "../../config/db.json";

class ReviewSection extends Component {
  state = {
    initData: {},
    data: [],
  };
  componentDidMount() {
    this.setState({
      heading: dbData.ReviewSection.heading,
      headingText: dbData.ReviewSection.headingText,
      headingTexttwo: dbData.ReviewSection.headingTexttwo,
      data: dbData.ReviewSection.data,
    });
  }
  render() {
    return (
      <section id="review" className="review-area ptb_100">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-md-10 col-lg-8">
              {/* Section Heading */}
              <div className="section-heading text-center">
                <h2 className="text-capitalize">{this.state.heading}</h2>
                <p className="d-none d-sm-block mt-4">
                  {this.state.headingText}
                </p>
                <p className="d-block d-sm-none mt-4">
                  {this.state.headingTexttwo}
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            {this.state.data.map((item, idx) => {
              return (
                <div
                  key={`ro_${idx}`}
                  className="col-12 col-md-6 col-lg-4 res-margin"
                >
                  {/* Single Review */}
                  <div className="single-review card">
                    {/* Card Top */}
                    <div className="card-top p-4">
                      <div className="review-icon">
                        <i className={item.icon_1} />
                        <i className={item.icon_2} />
                        <i className={item.icon_3} />
                        <i className={item.icon_4} />
                        <i className={item.icon_5} />
                      </div>
                      <h4 className="text-primary mt-4 mb-3">
                        {item.comments}
                      </h4>
                      {/* Review Text */}
                      <div className="review-text">
                        <p>{item.text}</p>
                      </div>
                      {/* Quotation Icon */}
                      <div className="quot-icon">
                        <img className="avatar-md" src={item.quotIcon} alt="" />
                      </div>
                    </div>
                    {/* Reviewer */}
                    <div className="reviewer media bg-gray p-4">
                      {/* Reviewer Thumb */}
                      <div className="reviewer-thumb">
                        <img
                          className="avatar-lg radius-100"
                          src={item.avatorImg}
                          alt=""
                        />
                      </div>
                      {/* Reviewer Media */}
                      <div className="reviewer-meta media-body align-self-center ml-4">
                        <h5 className="reviewer-name color-primary mb-2">
                          {item.reviewer}
                        </h5>
                        <h6 className="text-secondary fw-6">{item.post}</h6>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    );
  }
}

export default ReviewSection;
