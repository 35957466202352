import React, { Component } from "react";
import dbData from "../../config/db.json";

class Header extends Component {
  state = {
    data: {},
  };

  componentDidMount() {
    this.setState({
      data: dbData.headerSection,
    });
  }
  render() {
    return (
      <header className="navbar navbar-sticky navbar-expand-lg navbar-dark">
        <div className="container position-relative">
          <a className="navbar-brand" href="/">
            <img
              className="navbar-brand-regular"
              src={this.state.data.image}
              alt="brand-logo"
            />
            <img
              className="navbar-brand-sticky"
              src={this.state.data.image}
              alt="sticky brand-logo"
            />
          </a>
          <button
            className="navbar-toggler d-lg-none"
            type="button"
            data-toggle="navbarToggler"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>
          <div className="navbar-inner">
            {/*  Mobile Menu Toggler */}
            <button
              className="navbar-toggler d-lg-none"
              type="button"
              data-toggle="navbarToggler"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon" />
            </button>
            <nav>
              <ul className="navbar-nav" id="navbar-nav">
                <li className="nav-item">
                  <a className="nav-link scroll" href="#home">
                    {this.state.data.home}
                  </a>
                </li>

                <li className="nav-item">
                  <a className="nav-link scroll" href="#features">
                    {this.state.data.features}
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link scroll" href="#screenshots">
                    {this.state.data.screenshots}
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link scroll" href="#pricing">
                    {this.state.data.pricing}
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link scroll" href="#faq">
                    {this.state.data.faq}
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link scroll" href="#team">
                    {this.state.data.team}
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link scroll" href="#download">
                    {this.state.data.download}
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link scroll" href="#contact">
                    {this.state.data.contact}
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </header>
    );
  }
}

export default Header;
