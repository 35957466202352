import React, { Component } from "react";

const initData = {
  heading: "200",
  text:
    "The page you are looking for might have been removed had its name changed or is temporarily unavailable.",
  btnText: "Go to Homepage",
};

class PrivacyPolicyPage extends Component {
  state = {
    initData: {},
  };
  componentDidMount() {
    this.setState({
      initData: initData,
    });
  }
  render() {
    return (
      <div className="inner inner-pages">
        <div className="main">
          <section
            id="home"
            className="section welcome-area inner-area bg-overlay h-100vh overflow-hidden"
          >
            <div className="container h-100">
              <div className="row align-items-center justify-content-start h-100">
                <div className="col-12 col-md-7">
                  <div className="welcome-intro error-area text-center">
                    {/*<h1 className="text-white">*/}
                    {/*  {this.state.initData.heading}*/}
                    {/*</h1>*/}
                    <p className="text-white my-4">
                      Polityka prywatności aplikacji mobilnej Odkrywca - Łódź
                      <ol>
                        <li>
                          Niniejszy dokument opisuje politykę prywatności
                          aplikacji mobilnej Odkrywca - Łódź (dalej
                          "aplikacja"). Krzysztof Józwiak, będący właścicielem
                          firmy ITSnap Krzysztof Józwiak w Łoddzi, przy ul.
                          Wyszynskiego 39, NIP: 7272342741 (dalej
                          "Organizator"), traktuje ochronę prywatności w
                          Internecie Użytkowników korzystających z aplikacji za
                          pośrednictwem Apple AppStore i Google Play store
                          priorytetowo i dokłada wszelkich starań, aby
                          prywatność ta była chroniona.
                        </li>
                        <li>
                          Niniejszy dokument opisuje politykę prywatności
                          aplikacji mobilnej Odkrywca - Łódź (dalej
                          "aplikacja"). Krzysztof Józwiak, będący właścicielem
                          firmy ITSnap Krzysztof Józwiak w Łoddzi, przy ul.
                          Wyszynskiego 39, NIP: 7272342741 (dalej
                          "Organizator"), traktuje ochronę prywatności w
                          Internecie Użytkowników korzystających z aplikacji za
                          pośrednictwem Apple AppStore i Google Play store
                          priorytetowo i dokłada wszelkich starań, aby
                          prywatność ta była chroniona.
                        </li>
                        <li>
                          Niniejszy dokument opisuje politykę prywatności
                          aplikacji mobilnej Odkrywca - Łódź (dalej
                          "aplikacja"). Krzysztof Józwiak, będący właścicielem
                          firmy ITSnap Krzysztof Józwiak w Łoddzi, przy ul.
                          Wyszynskiego 39, NIP: 7272342741 (dalej
                          "Organizator"), traktuje ochronę prywatności w
                          Internecie Użytkowników korzystających z aplikacji za
                          pośrednictwem Apple AppStore i Google Play store
                          priorytetowo i dokłada wszelkich starań, aby
                          prywatność ta była chroniona.
                        </li>
                      </ol>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

export default PrivacyPolicyPage;
