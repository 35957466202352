import React, { Component } from "react";
import axios from "axios";
import dbData from "../../config/db.json";

class ContactForm extends Component {
  constructor(props) {
    super(props);
    this.myForm = React.createRef();
  }

  state = {
    name: "",
    email: "",
    subject: "",
    message: "",
  };

  changHandler = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };
  submitHangler = (event) => {
    const toast = document.getElementById("toast");
    event.preventDefault();
    console.log(this.state);
    const data = {
      email: this.state.email,
      name: this.state.name,
      content: this.state.message,
      subject: this.state.subject,
    };

    const headers = {
      headers: {
        "Content-Type": "application/json",
        "x-api-key": dbData.api.key,
      },
    };

    axios
      .post(dbData.api.link + "/sendemail", data, headers)
      .then((response) => {
        toast.innerHTML = dbData.contactForm.thanksForMessage;
      })
      .catch((error) => {
        console.log(error);
        toast.innerHTML = dbData.contactForm.messageError;
      });
    this.myForm.current.reset();
    this.setState({
      name: "",
      email: "",
      subject: "",
      message: "",
    });
  };

  render() {
    return (
      <div className="contact-box text-center">
        <div id="toast" />
        <form
          ref={this.myForm}
          onSubmit={this.submitHangler}
          className="contact-form"
          noValidate="novalidate"
        >
          <div className="row">
            <div className="col-12">
              <div className="form-group">
                <input
                  id="name"
                  type="text"
                  className="form-control"
                  name="name"
                  placeholder={dbData.contactForm.placeholderName}
                  required="required"
                  onChange={this.changHandler}
                  value={this.state.name}
                />
              </div>
              <div className="form-group">
                <input
                  id="email"
                  type="email"
                  className="form-control"
                  name="email"
                  placeholder={dbData.contactForm.placeholderEmail}
                  required="required"
                  onChange={this.changHandler}
                  value={this.state.email}
                />
              </div>
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  id="subject"
                  name="subject"
                  placeholder={dbData.contactForm.placeholderSubject}
                  required="required"
                  onChange={this.changHandler}
                  value={this.state.subject}
                />
              </div>
            </div>
            <div className="col-12">
              <div className="form-group">
                <textarea
                  className="form-control"
                  id="message"
                  name="message"
                  e2eSelector="form_message"
                  placeholder={dbData.contactForm.placeholderMessage}
                  required="required"
                  onChange={this.changHandler}
                  value={this.state.message}
                />
              </div>
            </div>
            <div className="col-12">
              <button
                id="send_message"
                type="submit"
                className="btn btn-lg btn-block mt-3"
              >
                <span className="text-white pr-3">
                  <i className="fas fa-paper-plane" />
                </span>
                {dbData.contactForm.sendMessage}
              </button>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default ContactForm;
